import React from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useMediaQuery from "use-media-query-hook";
import Contentwithoutsb from "../components/Contentwithoutsb";
import BlockProductList from "../components/BlockProductList";
import HeroSectionSmall from "../components/BlockSmallHero";
import Seo from "../components/SEO";

const SingleProductWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const SingleProductImageWrapper = styled.div`
  border-radius: var(--border-regular);
  background: var(--grey-dark);
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
`;

const SingleProductImageLogoAndInfoWrapper = styled.div`
  @media (min-width: 768px) {
    width: 50%;
    padding-right: 2rem;
  }
`;

const SingleProductLogoAndInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    margin-top: auto;
  }
`;

const SingleProductLogoWrapper = styled.div`
  display: flex;
  width: 25%;
  background: var(--white);
  border-radius: var(--border-regular);
  border: 2px solid var(--grey-dark);
  overflow: hidden;
  margin-right: 2rem;
  transition: border-color 0.4s ease;

  @media (min-width: 768px) {
    width: 8rem;
    height: 8rem;

    &:hover {
      border-color: var(--black);
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const SingleProductTextWrapper = styled.div`
  p {
    font-size: 1.6rem;
    font-family: var(--font-oswald-regular);
    text-transform: uppercase;
    margin-bottom: 0.2rem;
    margin-top: 0px;

    a {
      color: var(--red-dark);
      transition: color 0.25s ease;

      &:hover {
        color: var(--black);
      }
    }
  }
`;

const SingleProductContentWrapper = styled.div`
  padding: 2rem 2rem 4rem;
  border: 1px solid var(--grey-light);
  border-radius: var(--border-regular);
  margin-top: 2rem;

  @media (min-width: 768px) {
    width: 50%;
    margin-top: 0px;
    height: auto;
    padding-bottom: 2.4rem;
    padding-top: 2.4rem;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

  span {
    display: inline-block;
    margin: 0px 0px 2rem;
    font-weight: 800;
    font-family: var(--font-chinese);
    font-size: 1.6rem;
    @media (min-width: 1024px) {
      font-size: 1.8rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin-top: 0px;
  }
`;

const SingleProductContentTitle = styled.h3`
  font-size: 2.1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--grey-dark);
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    font-size: 2.8rem;
  }
`;

const ProductLogoInfo = ({
  productRange,
  productInfo,
  productCode,
  productBrandName,
  productBrandLink,
  productBrandImage,
  productRangeLink,
}) => {
  return (
    <SingleProductLogoAndInfo>
      <SingleProductLogoWrapper>
        <Link to={`/our-brands/${productBrandLink}`}>
          <GatsbyImage image={productBrandImage} alt={productBrandName} />
        </Link>
      </SingleProductLogoWrapper>
      <SingleProductTextWrapper>
        <p>
          <strong>range:</strong>{" "}
          <Link to={`/our-products/${productRangeLink}`}>{productRange}</Link>
        </p>
        <p>
          <strong>item code:</strong> {productCode}
        </p>
        <p>
          <strong>info:</strong> {productInfo}
        </p>
      </SingleProductTextWrapper>
    </SingleProductLogoAndInfo>
  );
};

export default function SingleProduct({ data }) {
  const product = data.product;
  const products = data.products.nodes;
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <>
      <Seo
        title={product.product_name}
        description={product.product_description}
        image={product.product_image?.asset?.url}
        location={useLocation()}
      />
      <HeroSectionSmall />
      <Contentwithoutsb>
        <SingleProductWrapper>
          <SingleProductImageLogoAndInfoWrapper>
            <SingleProductImageWrapper>
              <GatsbyImage
                image={product.product_image.asset.gatsbyImageData}
                alt={product.product_name}
              />
            </SingleProductImageWrapper>
            {!isDesktop && (
              <ProductLogoInfo
                productRange={product.product_range.range_name}
                productInfo={product.product_info}
                productCode={product.product_code}
                productBrandName={product.product_brand.brand_name}
                productBrandLink={product.product_brand.brand_slug.current}
                productBrandImage={
                  product.product_brand.brand_image.asset.gatsbyImageData
                }
                productRangeLink={product.product_range.range_slug.current}
              />
            )}
          </SingleProductImageLogoAndInfoWrapper>
          <SingleProductContentWrapper>
            <SingleProductContentTitle>
              {product.product_name}
            </SingleProductContentTitle>
            <span>{product.product_chinese_name}</span>
            <p>{product.product_description}</p>
            {isDesktop && (
              <ProductLogoInfo
                productRange={product.product_range.range_name}
                productInfo={product.product_info}
                productCode={product.product_code}
                productBrandName={product.product_brand.brand_name}
                productBrandLink={product.product_brand.brand_slug.current}
                productBrandImage={
                  product.product_brand.brand_image.asset.gatsbyImageData
                }
                productRangeLink={product.product_range.range_slug.current}
              />
            )}
          </SingleProductContentWrapper>
        </SingleProductWrapper>
      </Contentwithoutsb>
      {products.length === 0 ? null : (
        <BlockProductList
          title='related product'
          link={`/our-products/${product.product_range.range_slug.current}`}
          linkText='view all related products'
          borderColor='var(--grey-light)'
          productList={products}
        />
      )}
    </>
  );
}

export const query = graphql`
  query ($slug: String!, $range: [String]) {
    product: sanityProduct(product_slug: { current: { eq: $slug } }) {
      id
      product_name
      product_chinese_name
      product_code
      product_info
      product_description
      product_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          url
        }
      }

      product_range {
        range_name
        range_slug {
          current
        }
      }

      product_brand {
        brand_name
        brand_slug {
          current
        }
        brand_image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }

    products: allSanityProduct(
      filter: { product_range: { range_name: { in: $range } } }
      sort: { fields: _createdAt, order: [DESC] }
      limit: 6
    ) {
      nodes {
        id
        product_slug {
          current
        }
        product_name
        product_chinese_name
        product_brand {
          brand_name
        }
        product_code
        product_info

        product_image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;
