import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const HeroSectionWrapper = styled.section`
  margin-bottom: 3rem;
  height: 12rem;
  display: flex;
  position: relative;
  overflow: hidden;
`;

const HeroImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: relative;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.8);

  @media (min-width: 768px) {
    background: linear-gradient(
      80deg,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0)
    );
  }
`;

const HeroImageBGWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  background-color: var(--black);
  z-index: -1;
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

export default function HeroSectionSmall() {
  return (
    <HeroSectionWrapper>
      <HeroImageWrapper></HeroImageWrapper>
      <HeroImageBGWrapper role='presentation'>
        <StaticImage
          src='../assets/images/shueyshing_single_product_bg.jpg'
          alt=''
          placeholder='blurred'
          formats={["auto", "webp"]}
        />
      </HeroImageBGWrapper>
    </HeroSectionWrapper>
  );
}
